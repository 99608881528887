import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Img, { GatsbyImageProps } from 'gatsby-image';
import Link, { GatsbyLinkProps } from 'gatsby-link';

import Heading, { HeadingVariant } from '../../elements/heading/heading';
import { COLOR_BLUE_LIGHT, COLOR_GREY } from './../../theme/colors/colors';
import { linkStyles } from '../../elements/link/link';

const NewsCardBody = styled.div`
  width: 100%;
  height: auto;

  ${Heading} {
    line-height: 1.3rem;
    margin-bottom: 0.5em;
  }
`;

const NewsCardLink = styled(props => <Link {...props} />)`
  ${linkStyles}
  color: ${COLOR_BLUE_LIGHT['900']};
  text-decoration: none;
  outline: none;

  &:focus {
    text-decoration: underline;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

const NewsCardPublishedAt = styled.time`
  color: ${COLOR_GREY};
  font-weight: 300;
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.5rem;
`;

const NewsCardSummary = styled.p`
  font-size: 0.875rem;
  margin-top: 0;
  color: #081f2c;
`;

// const NewsCardCTA = styled.span``;

const NewsCardImage = styled.figure`
  width: 100%;
  padding: 0;
  margin: 0 0 1rem;

  img {
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const NewsCardArticle = styled.article`
  /* Inspired by https://inclusive-components.design/cards/ */
  display: flex;
  flex-direction: column;
  position: relative;

  &:focus-within {
    box-shadow: 0px 0px 0px 3px ${COLOR_BLUE_LIGHT['200']};
  }

  &:focus-within ${NewsCardLink}:focus {
    text-decoration: none;
  }

  ${NewsCardImage} {
    order: 1;
  }

  ${NewsCardBody} {
    order: 2;
  }
`;

interface NewsCardProps {
  headingLevel: HeadingVariant;
  title: string;
  summary: string;
  linkProps: GatsbyLinkProps<{}>;
  publishedAt: string;
  publishedAtFormatted: string;
  imageProps: GatsbyImageProps;
}

export default function NewsCard({
  headingLevel,
  title,
  summary,
  linkProps,
  publishedAt,
  publishedAtFormatted,
  imageProps,
}: NewsCardProps): ReactElement {
  return (
    <NewsCardArticle>
      <NewsCardBody>
        <Heading as={headingLevel} variant="h6" hasMargin={false}>
          <NewsCardLink {...linkProps}>{title}</NewsCardLink>
        </Heading>
        <NewsCardPublishedAt dateTime={publishedAt}>
          {publishedAtFormatted}
        </NewsCardPublishedAt>
        <NewsCardSummary>{summary}</NewsCardSummary>
        {/* <NewsCardCTA>Verder lezen</NewsCardCTA> */}
      </NewsCardBody>
      <NewsCardImage>
        <Img {...imageProps} />
      </NewsCardImage>
    </NewsCardArticle>
  );
}
