import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { GatsbyImageProps } from 'gatsby-image';

import Container from '@csv/styleguide/src/elements/container/container';
import NewsCard from '@csv/styleguide/src/features/news-card/news-card';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { mapEdgesToNodes } from '../lib/helpers';
import {
  AllPostsQuery,
  ImageSharpFluid,
  SanityPost,
} from '../generated/graphql-types';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';

interface NewsPageProps {
  data: AllPostsQuery;
}

export const renderPost = (
  post: SanityPost,
  defaultImage?: ImageSharpFluid,
) => {
  const { title, mainImage, id, slug, publishedAt, summary } = post;
  const postSlug = slug?.current || id;

  return (
    <NewsCard
      key={id}
      headingLevel="h3"
      title={title || ''}
      summary={summary || ''}
      linkProps={{ to: `/nieuws/${postSlug}` }}
      publishedAt={publishedAt}
      publishedAtFormatted={
        format(new Date(publishedAt), 'dd-MM-yyyy', { locale: nl }) || ''
      }
      imageProps={{
        // the below breaks if no image is set!!
        fluid:
          (mainImage?.image?.asset?.fluid as GatsbyImageProps['fluid']) ||
          defaultImage,
        alt: mainImage?.alt || '',
        style: {
          width: '100%',
          height: 'auto',
        },
      }}
    />
  );
};

const NewsPageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 370px));
  grid-gap: 32px;
  justify-content: center;
`;

const NewsPage = ({ data }: NewsPageProps) => {
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const hasPosts = postNodes && postNodes.length;
  const defaultImage = data?.defaultImage?.childImageSharp?.fluid;

  return (
    <Layout>
      <SEO
        title="Clubnieuws"
        description="Al het clubnieuws van CSV Handbal Castricum"
      />
      <PageHeaderComposition
        title={'Nieuws'}
        byline="Hier vindt je het allerbelangrijkste clubnieuws.
Wist je dat we ook een Facebookpagina hebben waar we regelmatig op posten? Volg onze Facebookpagina."
      />
      <Container>
        {hasPosts && (
          <NewsPageGrid>
            {postNodes.map(post => renderPost(post, defaultImage))}
          </NewsPageGrid>
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query AllPosts {
    posts: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
          summary
          mainImage {
            alt
            image {
              asset {
                # fixed(width: 320) {
                #   src
                #   srcSet
                #   height
                # }
                fluid(maxWidth: 370, maxHeight: 208) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    defaultImage: file(relativePath: { eq: "csv-vlag.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 370, maxHeight: 208) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default NewsPage;
