import React, { ReactElement, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';

import { graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';

import Container from '@csv/styleguide/src/elements/container/container';

import {
  ScrollableContainer,
  ScrollableItem,
} from '@csv/styleguide/src/features/scrollable-container/scrollable-container';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Heading from '@csv/styleguide/src/elements/heading/heading';
import styled from 'styled-components';
import Section from '@csv/styleguide/src/elements/section/section';
import { mapEdgesToNodes } from '../lib/helpers';
import { renderPost } from './nieuws';
import { linkStyles } from '@csv/styleguide/src/elements/link/link';
import { IndexPageQueryQuery } from './../generated/graphql-types';
import Table from '@csv/styleguide/src/elements/table/table';
import {
  TeamCardListItem,
  TeamCardUnorderedList,
} from '@csv/styleguide/src/features/team-card-list/team-card-list';
import TeamCard from '@csv/styleguide/src/features/team-card/team-card-v1';
// import TaakieLogo from '../images/taakie-logo.png';
import { StyledButton } from '@csv/styleguide/src/elements/button/button';

interface IndexPageProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: IndexPageQueryQuery;
}

const DummyGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  ${ScrollableItem} {
    min-width: 320px;
    max-width: 320px;
    margin-right: 16px;
  }
`;

const HomePageGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0.5rem;
`;

const HeroImage = styled.div`
  margin-bottom: -100px;
  border-radius: 8px;
  overflow: hidden;
  width: calc(100% - 64px);
  max-width: 900px;
  margin-left: 32px;
  margin-right: 32px;

  @media screen and (min-width: 932px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const HeroWrapper = styled.div`
  background: #2041a3;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  padding-top: 2rem;
  margin-bottom: 100px;

  ${Heading} {
    /* @SEE https://css-tricks.com/simplified-fluid-typography/ */
    font-weight: 900;
    --minFontSize: 20px;
    --maxFontSize: 40px;
    --scaler: 5vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  }

  p {
    max-width: 605px;
    margin: 0 16px 32px;
    @media screen and (min-width: 650px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${HeroImage} {
    position: relative;
    z-index: 1;
  }

  svg {
    display: block;
    position: absolute;
    transform: rotate(180deg);
    width: 100%;
    bottom: -1px;
    left: 0;
    right: 0;
    top: auto;

    path {
      fill: white;
    }
  }
`;
export interface HeroProps {
  headline: string;
  subline: string;
  image?: JSX.Element;
}

export function Hero({ headline, subline, image }: HeroProps): ReactElement {
  return (
    <HeroWrapper>
      <Heading as="h1" variant="h1">
        {headline}
      </Heading>
      <p>{subline}</p>

      {image && <HeroImage>{image}</HeroImage>}
      <svg viewBox="0 0 800 50" preserveAspectRatio="none">
        <g>
          <path d="M0,0 L800,0 L800,50 C666.666667,22.5877193 533.333333,8.88157895 400,8.88157895 C266.666667,8.88157895 133.333333,22.5877193 0,50 L0,0 Z"></path>
        </g>
      </svg>
    </HeroWrapper>
  );
}

export const SectionLink = styled(props => <Link {...props} />)`
  ${linkStyles}
  line-height: 1.5;
  display: flex;
  align-items: center;

  span {
  }

  svg {
    font-size: 0.875rem;
    margin-left: 0.5rem;
  }
`;

const teamsInfo = {
  Senioren: {
    Zaal: {
      count: 0,
    },
    Veld: {
      count: 0,
    },
  },
  Jeugd: {
    Zaal: {
      count: 0,
    },
    Veld: {
      count: 0,
    },
  },
  Rolstoelhandbal: {
    count: 0,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IndexPage = ({ data }: IndexPageProps) => {
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const hasPosts = postNodes && postNodes.length;
  // const [events, setEvents] = useState<SanityEvent[]>([]);
  const [upcomingMatches, setUpcomingMatches] = useState<any[]>([]);
  const [upcomingMatchesStatus, setUpcomingMatchesStatus] = useState('idle');
  const [teams, setTeams] = useState<typeof teamsInfo>(teamsInfo);
  const defaultImage = data?.defaultImage?.childImageSharp?.fluid;

  async function getUpcomingMatches() {
    setUpcomingMatchesStatus('loading');
    try {
      const response = await fetch(
        'https://data.sportlink.com/programma?gebruiklokaleteamgegevens=NEE&aantaldagen=7&eigenwedstrijden=JA&thuis=JA&uit=JA&plaats=JA&client_id=sUeFWjaLeG',
      );
      const data = await response.json();

      setUpcomingMatches(data);
      setUpcomingMatchesStatus('success');
    } catch {
      setUpcomingMatchesStatus('error');
    }
  }

  // useEffect(() => {
  //   const eventNodes = data && data.events && mapEdgesToNodes(data.events);

  //   const upcomingEvents = eventNodes.filter((event: SanityEvent) => {
  //     const dateToCompareTo = event.endDatetime || event.startDatetime;

  //     if (!dateToCompareTo) {
  //       return false;
  //     }
  //     return compareAsc(Date.now(), new Date(dateToCompareTo)) < 0
  //       ? true
  //       : false;
  //   });

  //   setEvents(upcomingEvents);
  // }, []);

  useEffect(() => {
    getUpcomingMatches();
  }, []);

  useEffect(() => {
    const newTeams = teamsInfo;
    data.sportlink?.GetTeams?.forEach(team => {
      if (!team) {
        return;
      }
      if (team?.competition?.includes('Senioren')) {
        if (newTeams['Senioren'][team.gameType]) {
          // fuck gatsby and typescript shit ... FML
          newTeams['Senioren'][team.gameType].count += 1;
          return;
        }
      } else if (team?.ageCategory?.includes('Jeugd')) {
        if (newTeams['Jeugd'][team.gameType]) {
          newTeams['Jeugd'][team.gameType].count += 1;
          return;
        }
      } else if (team?.competition === 'Rolstoelhandbal') {
        newTeams['Rolstoelhandbal'].count += 1;
        return;
      }
    });
    setTeams(newTeams);
  }, [data.sportlink?.GetTeams?.length]);

  return (
    <Layout>
      {/* @TODO change the SEO with own metadata */}
      <SEO title="Home" description="This is the website of Somebody" />
      {data && (
        <Hero
          headline="Internationaal handbaltoernooi bij CSV"
          subline="Op zaterdag 15 juni 2024 is het zover: een spectaculair handbaltoernooi staat op het punt te beginnen! 🤾‍♂️ Deelnemende teams van over de grenzen komen samen voor een dag vol opwinding en sportiviteit. "
          image={<Img fluid={data.file.childImageSharp.fluid} />}
        />
      )}

      <Container style={{ marginTop: 40 }}>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '32px',
          }}
        >
          <StyledButton
            style={{
              padding: '8px 12px',
              cursor: 'pointer',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
            kind="default"
            onClick={() => navigate('/over-csv/jubileum/')}
          >
            Jubileuminformatie en aanmelden
          </StyledButton>
        </div> */}
        <HomePageGrid>
          <Section>
            <Heading variant="h3" as="h2">
              Laatste nieuws
            </Heading>
            <ScrollableContainer>
              {hasPosts && (
                <DummyGrid>
                  {postNodes.map((post, i) => (
                    <ScrollableItem key={i}>
                      {renderPost(post, defaultImage)}
                    </ScrollableItem>
                  ))}
                </DummyGrid>
              )}
            </ScrollableContainer>
            <SectionLink to="/nieuws">
              <span>Al het clubnieuws</span>
              <FaArrowRight />
            </SectionLink>
          </Section>
          {/* {events?.length ? (
            <Section>
              <Heading variant="h3" as="h2">
                Komende evenementen
              </Heading>
              {events.map((event: SanityEvent, i: number) => {
                const {
                  slug,
                  title,
                  location,
                  startDatetime,
                  endDatetime,
                } = event;
                const { day, month } = formatDate(startDatetime);
                return (
                  <EventCard
                    key={i}
                    href={`/agenda/${slug?.current ?? ''}`}
                    name={title || ''}
                    time={formatTimeRange(startDatetime, endDatetime)}
                    address={location || ''}
                    day={day}
                    month={month}
                  />
                );
              })}
              <SectionLink to="/agenda">
                <span>Alle evenementen</span>
                <FaArrowRight />
              </SectionLink>
            </Section>
          ) : null} */}
          <Section>
            <Heading variant="h3" as="h2">
              Onze teams
            </Heading>
            <div>
              <TeamCardUnorderedList>
                {teams.Rolstoelhandbal.count > 0 && (
                  <TeamCardListItem key={'Rolstoelhandbal'}>
                    <TeamCard
                      href={`/teams`}
                      heading={'Rolstoelhandbal'}
                      subHeading={`${teams.Rolstoelhandbal.count} team${
                        teams.Rolstoelhandbal.count > 1 ? 's' : ''
                      }`}
                    />
                  </TeamCardListItem>
                )}
                {teams.Senioren.Veld.count > 0 && (
                  <TeamCardListItem key={'SeniorenVeld'}>
                    <TeamCard
                      href={`/teams`}
                      heading={'Senioren - Veld'}
                      subHeading={`${teams.Senioren.Veld.count} team${
                        teams.Senioren.Veld.count > 1 ? 's' : ''
                      }`}
                    />
                  </TeamCardListItem>
                )}
                {teams.Senioren.Zaal.count > 0 && (
                  <TeamCardListItem key={'SeniorenZaal'}>
                    <TeamCard
                      href={`/teams`}
                      heading={'Senioren - Zaal'}
                      subHeading={`${teams.Senioren.Zaal.count} team${
                        teams.Senioren.Zaal.count > 1 ? 's' : ''
                      }`}
                    />
                  </TeamCardListItem>
                )}
                {teams.Jeugd.Veld.count > 0 && (
                  <TeamCardListItem key={'JeugdVeld'}>
                    <TeamCard
                      href={`/teams`}
                      heading={'Jeugd - Veld'}
                      subHeading={`${teams.Jeugd.Veld.count} team${
                        teams.Jeugd.Veld.count > 1 ? 's' : ''
                      }`}
                    />
                  </TeamCardListItem>
                )}
                {teams.Jeugd.Zaal.count > 0 && (
                  <TeamCardListItem key={'JeugdZaal'}>
                    <TeamCard
                      href={`/teams`}
                      heading={'Jeugd - Zaal'}
                      subHeading={`${teams.Jeugd.Zaal.count} team${
                        teams.Jeugd.Zaal.count > 1 ? 's' : ''
                      }`}
                    />
                  </TeamCardListItem>
                )}
              </TeamCardUnorderedList>
            </div>
          </Section>
          <Section>
            <Heading variant="h3" as="h2">
              Wedstrijdprogramma komende week
            </Heading>
            {upcomingMatchesStatus === 'loading'
              ? 'Wedstrijddata wordt geladen'
              : null}
            {upcomingMatchesStatus === 'error'
              ? 'Wedstrijddata kon niet worden geladen.'
              : null}
            {upcomingMatchesStatus === 'success' && upcomingMatches.length > 0 && (
              <div style={{ marginBottom: '1rem' }}>
                <Table minWidth="100%" bordered>
                  <thead>
                    <tr>
                      <th>Datum en tijd</th>
                      <th>Thuisteam</th>
                      <th>Uitteam</th>
                      <th>Locatie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {upcomingMatches.map((match: any, i: number) => {
                      const {
                        datum,
                        aanvangstijd,
                        thuisteam,
                        uitteam,
                        accommodatie,
                        wedstrijdcode,
                        plaats,
                      } = match;
                      return (
                        <tr key={wedstrijdcode}>
                          <td>
                            {datum} {aanvangstijd}
                          </td>
                          <td>{thuisteam}</td>
                          <td>{uitteam}</td>
                          <td>{accommodatie}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              // <pre>{JSON.stringify(upcomingMatches, null, 2)}</pre>
            )}
            <SectionLink to="/teams">
              <span>Alle competitie-informatie</span>
              <FaArrowRight />
            </SectionLink>
          </Section>
          {/* <Section>
            <Heading variant="h3" as="h2">
              Taakie voor vrijwilligerstaken
            </Heading>
            <div style={{ display: 'flex', gap: '32px' }}>
              <div style={{ maxWidth: '50%' }}>
                <p>
                  Taakie is een handige app waarmee we vrijwilligerstaken
                  beheren en uitsturen. Door je aan te melden, kun je je
                  voorkeuren aangeven en krijg je een melding als er een taak
                  voor je is. Hierdoor draag je jouw steentje bij aan de club en
                  kun je zelf bepalen wanneer je een taak doet. Taakies tellen
                  uiteraard mee voor de teruggave van de{' '}
                  <Link to="/over-csv/vrijwilligers/#c8704e746f35">
                    vrijwilligersbijdrage
                  </Link>
                  .
                </p>
                <StyledButton
                  kind="primary"
                  as={'a'}
                  href="https://admin.taakie.nl/kennismaken/?ref=289390ba-18f0-4970-a19a-3e6de8ba73d4"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', display: 'inline-block' }}
                  target="_blank"
                >
                  Ik meld me aan bij Taakie
                </StyledButton>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <a
                  href="https://admin.taakie.nl/kennismaken/?ref=289390ba-18f0-4970-a19a-3e6de8ba73d4"
                  rel="noopener noreferrer"
                >
                  <img
                    src={TaakieLogo}
                    alt="Taakie logo"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </a>
              </div>
            </div>
          </Section> */}
        </HomePageGrid>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    sportlink {
      GetTeams {
        ageCategory
        competition
        competitionType
        divisionName
        gameType
        gender
        groupName
        teamName
        teamId
        teamType
      }
    }
    file(relativePath: { eq: "int-handbaltoernooi-crop.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    defaultImage: file(relativePath: { eq: "csv-vlag.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 370, maxHeight: 208) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    posts: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 8) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publishedAt
          summary
          mainImage {
            alt
            image {
              asset {
                # fixed(width: 320) {
                #   src
                #   srcSet
                #   height
                # }
                fluid(maxWidth: 370, maxHeight: 208) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }

    # events: allSanityEvent {
    #   edges {
    #     node {
    #       _id
    #       endDatetime
    #       id
    #       _id
    #       location
    #       slug {
    #         current
    #       }
    #       startDatetime
    #       title
    #       _rawDescription
    #     }
    #   }
    # }
  }
`;

export default IndexPage;
