import styled from 'styled-components';

export const ScrollableContainer = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x proximity;
  max-width: 100%;
`;

export const ScrollableItem = styled.div`
  scroll-snap-align: center;
`;
