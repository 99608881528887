import styled, { css } from 'styled-components';
import { COLOR_BLUE_LIGHT } from '../../theme/colors/colors';

export const linkStyles = css`
  color: ${COLOR_BLUE_LIGHT['500']};
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;

  &:focus,
  &:hover {
    color: ${COLOR_BLUE_LIGHT['900']};
  }

  /* &:visited {
    color: ${COLOR_BLUE_LIGHT['800']};
  } */
`;

export const Link = styled.a`
  ${linkStyles}
`;
